<template>
  <div class="page">
    <b-container fluid>
      <b-row class="accounts-page row-cols-2">
        <b-col class="items-col col-6 col-xl-5">
          <ButtonItem
            :count="usersCount"
            :text="{
              title: $tc('words.user', usersCount),
              article: $tc('words.pronounMasculine', usersCount)
            }"
            :icon="'face_user'"
            :route-to="['Users', 'AddUser']"
            :loading="!isUsersListLoaded"
          />
          <ButtonItem
            :count="organisationsCount"
            :text="{
              title: $tc('words.organisation', organisationsCount),
              article: $tc('words.pronounFeminine', organisationsCount)
            }"
            :icon="'company'"
            :route-to="['Organisations', 'AddOrganisation']"
            :loading="!isOrganisationsListLoaded"
          />
          <ButtonItem
            :count="groupsCount"
            :text="{
              title: $tc('words.userGroup', groupsCount),
              article: $tc('words.pronounMasculine', groupsCount)
            }"
            :icon="'user-group'"
            :route-to="['Groups', 'AddGroup']"
            :loading="!isGroupsListLoaded"
          />
          <ButtonItem
            :count="spheresCount"
            :text="{
              title: $tc('words.organisationGroup', spheresCount),
              article: $tc('words.pronounMasculine', spheresCount)
            }"
            :icon="'sphere_planet'"
            :route-to="['Spheres', 'AddSphere']"
            :loading="!isSpheresListLoaded"
          />
        </b-col>

        <b-col class="col-5 col-xs-4">
          <div>
            <h2>
              {{ capitalizeFirstLetter($tc('words.request', 2)) }}
            </h2>
            <div>
              <div class="demandes-count">
                {{ capitalizeFirstLetter($tc('words.user', 2)) }}
                <span style="float: right; min-width: 8em;">
                  <b-overlay
                    :show="!demandesLoading && reloadingUsersDemandes"
                    :variant="'white'"
                    rounded="lg"
                    opacity="0.9"
                  >
                    <template #overlay>
                      <div class="d-flex align-items-center">
                        <b-spinner
                          small
                          variant="secondary"
                        />
                      </div>
                    </template>
                    <div v-if="usersDemandesCount > 0">
                      {{ usersDemandesCount }} {{ $tc('words.request', usersDemandesCount) }}
                    </div>
                  </b-overlay>
                </span>
              </div>
              <b-overlay
                id="overlay-background"
                :show="demandesLoading"
                :variant="'white'"
                :opacity="0.6"
                rounded="sm"
                blur="2px"
                style="width: 100%; min-height: 6rem;"
              >
                <DemandesItem
                  v-for="demande of usersDemandesList"
                  :key="demande.id"
                  :demande="demande"
                  :type="$tc('words.user')"
                  :icon="'face_user'"
                  :route-to="'UserDetail'"
                />
                <div
                  v-if="usersDemandesCount === 0 && !demandesLoading"
                  class="no-demandes"
                >
                  {{ $t('accounts.noAccountsTasks') }} .
                </div>
              </b-overlay>
            </div>
            <div>
              <div class="demandes-count">
                {{ capitalizeFirstLetter($tc('words.organisation', 2)) }}
                <span style="float: right;">
                  <b-overlay
                    :show="!demandesLoading && reloadingUsergroupsDemandes"
                    :variant="'white'"
                    rounded="lg"
                    opacity="0.9"
                  >
                    <template #overlay>
                      <div class="d-flex align-items-center">
                        <b-spinner
                          small
                          variant="secondary"
                        />
                      </div>
                    </template>
                    <div v-if="usergroupsDemandesCount > 0">
                      {{ usergroupsDemandesCount }} {{ $tc('words.request', usergroupsDemandesCount) }}
                    </div>
                  </b-overlay>
                </span>
              </div>
              <b-overlay
                id="overlay-background"
                :show="demandesLoading"
                :variant="'white'"
                :opacity="0.6"
                rounded="sm"
                blur="2px"
                style="width: 100%; min-height: 6rem;"
              >
                <DemandesItem
                  v-for="demande of usergroupsDemandesList"
                  :key="demande.id"
                  :demande="demande"
                  :type="demande.usergroup_type.display_name"
                  :icon="'user-group'"
                  :route-to="'OrganisationDetail'"
                />
                <div
                  v-if="usergroupsDemandesCount === 0 && !demandesLoading"
                  class="no-demandes"
                >
                  {{ $t('accounts.noOrganisationTasks')}} .
                </div>
              </b-overlay>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ButtonItem from '@/components/ButtonItem.vue';
import DemandesItem from '@/components/DemandesItem.vue';

import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'Accounts',

  components : {
    ButtonItem,
    DemandesItem
  },

  data() {
    return {
      isUsersListLoaded: false,
      isOrganisationsListLoaded: false,
      isGroupsListLoaded: false,
      isSpheresListLoaded: false,
      isUsersDemandesListLoaded: false,
      isUsergroupsDemandesListLoaded: false,
      reloadingUsersDemandes: false,
      reloadingUsergroupsDemandes: false,
      demandesLoading: false
    };
  },

  computed: {
    ...mapState('users', [
      'usersCount',
      'usersList',
      'isUsersListSearched',
      'currentUsersFilters'
    ]),
    ...mapState('organisations', [
      'organisationsCount',
      'organisationsList',
      'isOrganisationsListSearched',
      'currentOrganisationsFilters'
    ]),
    ...mapState('usergroups', [
      'usergroupsList',
      'spheres',
      'spheresCount',
      'groups',
      'groupsCount',
      'isGroupsSearched',
      'isSpheresSearched'
    ]),
    ...mapState('demandes', [
      'usersDemandesCount',
      'usersDemandesList',
      'usergroupsDemandesCount',
      'usergroupsDemandesList'
    ])
  },

  created() {
    this.getConfigData();
    this.getData();
  },

  mounted() {
    this.SET_RELOAD_INTERVAL_ID({
      name: 'accounts_reload',
      interval: setInterval(() => {
        this.reloadingUsersDemandes = true;
        this.GET_USERS_DEMANDES_LIST({
          direction: '-',
          field: 'date_joined'
        })
          .then(() => {
            this.reloadingUsersDemandes = false;
          })
          .catch(() => {
            this.reloadingUsersDemandes = false;
          });
        this.reloadingUsergroupsDemandes = true;
        this.GET_USERGROUPS_DEMANDES_LIST({
          direction: '-',
          field: 'date_joined'
        })
          .then(() => {
            this.reloadingUsergroupsDemandes = false;
          })
          .catch(() => {
            this.reloadingUsergroupsDemandes = false;
          });
      }, process.env.VUE_APP_RELOAD_INTERVAL)
    });
  },

  methods: {
    ...mapMutations(['SET_RELOAD_INTERVAL_ID']),
    ...mapMutations('users', [
      'SET_IS_USERSLIST_SEARCHED'
    ]),
    ...mapMutations('organisations', [
      'SET_IS_ORGANISATIONSLIST_SEARCHED'
    ]),
    ...mapActions('users', [
      'GET_USERS_LIST',
      'PRESET_USERS_FILTERS',
      'PREREMOVE_USERS_FILTERS'
    ]),
    ...mapActions('organisations', [
      'GET_ORGANISATIONS_TYPES',
      'GET_ORGANISATIONS_LIST',
      'PRESET_ORGANISATIONS_FILTERS',
      'PREREMOVE_ORGANISATIONS_FILTERS'
    ]),
    ...mapActions('usergroups', [
      'GET_USERGROUPS_LIST',
      'GET_USERGROUPS_GROUPS_LIST',
      'GET_USERGROUPS_SPHERES_LIST',
      'GET_USERGROUPS_ROLES'
    ]),
    ...mapActions('demandes', [
      'GET_USERS_DEMANDES_LIST',
      'GET_USERGROUPS_DEMANDES_LIST'
    ]),

    getConfigData() {
      this.GET_ORGANISATIONS_TYPES();
      this.GET_USERGROUPS_ROLES();
    },

    getData() {
      this.getUsersList();
      this.getOrganisationsList();
      this.getGroupsList();
      this.getSpheresList();
      this.getUsergroupsList();
      this.getUsersDemandesList();
      this.getUsergroupsDemandesList();
    },

    getUsersList() {
      if (this.usersList.length === 0 || this.isUsersListSearched || this.currentUsersFilters.status__in !== [2]) {
        if (this.currentUsersFilters.status__in !== [2]) {
          this.PREREMOVE_USERS_FILTERS({
            filter: 'status__in',
            value: this.currentUsersFilters.status__in[0]
          });
          this.PRESET_USERS_FILTERS({
            filter: 'status__in',
            value: 2
          });
        }
        this.SET_IS_USERSLIST_SEARCHED({
          isSearched: false,
          text: null
        });
        this.GET_USERS_LIST({
          direction: null,
          field: null
        }).then(() => {
          this.isUsersListLoaded = true;
        }).catch(() => {
          this.isUsersListLoaded = true;
        });
      } else {
        this.isUsersListLoaded = true;
      }
    },
    getOrganisationsList() {
      if (this.organisationsList.length === 0 || this.isOrganisationsListSearched || this.currentOrganisationsFilters.usergroup__status__in !== [2]) {
        if (this.currentOrganisationsFilters.usergroup__status__in !== [2]) {
          this.PREREMOVE_ORGANISATIONS_FILTERS({
            filter: 'usergroup__status__in',
            value: this.currentOrganisationsFilters.usergroup__status__in[0]
          });
          this.PRESET_ORGANISATIONS_FILTERS({
            filter: 'usergroup__status__in',
            value: 2
          });
        }
        this.SET_IS_ORGANISATIONSLIST_SEARCHED({
          isSearched: false,
          text: null
        });
        this.GET_ORGANISATIONS_LIST({
          direction: null,
          field: null
        }).then(() => {
          this.isOrganisationsListLoaded = true;
        }).catch(() => {
          this.isOrganisationsListLoaded = true;
        });
      } else {
        this.isOrganisationsListLoaded = true;
      }
    },
    getGroupsList() {
      if (this.groups.length === 0 || this.isGroupsSearched) {
        this.GET_USERGROUPS_GROUPS_LIST({
          direction: null,
          field: null
        }).then(() => {
          this.isGroupsListLoaded = true;
        }).catch(() => {
          this.isGroupsListLoaded = true;
        });
      } else {
        this.isGroupsListLoaded = true;
      }
    },
    getSpheresList() {
      if (this.spheres.length === 0 || this.isSpheresSearched) {
        this.GET_USERGROUPS_SPHERES_LIST({
          direction: null,
          field: null
        }).then(() => {
          this.isSpheresListLoaded = true;
        }).catch(() => {
          this.isSpheresListLoaded = true;
        });
      } else {
        this.isSpheresListLoaded = true;
      }
    },
    getUsergroupsList() {
      if (this.usergroupsList.length === 0 || this.isGroupsSearched || this.isSpheresSearched) {
        this.GET_USERGROUPS_LIST({
          direction: null,
          field: null,
          usergroupTypes: []
        });
      }
    },
    getUsersDemandesList() {
      this.demandesLoading = true;
      this.GET_USERS_DEMANDES_LIST({
        direction: '-',
        field: 'date_joined'
      }).then(() => {
        this.isUsersDemandesListLoaded = true;
        this.demandesLoading = false;
      }).catch(() => {
        this.isUsersDemandesListLoaded = true;
        this.demandesLoading = false;
      });
    },
    getUsergroupsDemandesList() {
      this.demandesLoading = true;
      this.GET_USERGROUPS_DEMANDES_LIST({
        direction: '-',
        field: 'date_joined'
      }).then(() => {
        this.isUsergroupsDemandesListLoaded = true;
        this.demandesLoading = false;
      }).catch(() => {
        this.isUsergroupsDemandesListLoaded = true;
        this.demandesLoading = false;
      });
    }
  }
};
</script>

<style lang="less">

.container-fluid {
  height: calc(100% - @footerHeight);
  width: 100%;
}

.demandes-count {
  width: 100%;
  right: 1em;
  top: 0.5em;
  margin-bottom: 0.1em;
}

.b-overlay .position-absolute {
  height: 97%;
}

.no-demandes {
  text-align: right;
  font-style: italic;
  color: rgb(82, 82, 82);
  font-size: 0.9em;
  margin-top: 1rem;
}
</style>
