<template>
  <div
    class="item"
    @click="$router.push({ name: routeTo, params: { id: demande.id } })"
  >
    <b-row class="bloc-row">
      <b-col cols="10">
        <b-row class="top-row">
          <b-col cols="2">
            <img
              v-if="icon.length"
              :src="getSvgUrl()"
              alt="Icon request button"
            >
          </b-col>
          <b-col cols="8">
            {{ demande.status === 1 ? 'Validation' : 'Vérification' }} {{ type }}
          </b-col>
        </b-row>
        <b-row class="bottom-row">
          <b-col>
            <div class="email">
              {{ demande.username || demande.codename }}
            </div>
            <div class="date">
              {{ demande.date_joined ?
                  new Date(demande.date_joined).toLocaleString('fr-FR', {year: "numeric", month: "numeric", day: "numeric"}) :
                  new Date(demande.creation_date).toLocaleString('fr-FR', {year: "numeric", month: "numeric", day: "numeric"}) }}
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="2" class="arrow-col">
        <b-icon-chevron-right class="h2" scale="1.5" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'DemandesItem',

  props: {
    type: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    routeTo: {
      type: String,
      default: 'UserDetail'
    },
    demande: {
      type: Object,
      default: () => { return {} }
    }
  },

  methods: {
    getSvgUrl() {
      const images = require.context('@/assets/icons/', false, /\.svg$/);
      return images(`./${this.icon}.svg`);
    }
  }
}
</script>

<style lang="less" scoped>

.item {
  cursor: pointer;
  height: 6em;
  margin-bottom: 0.5em;
  color: @blue;
  border-radius: 3px;
  border: 1px solid #A9B2B9;
  box-shadow: 0 0 1px 1px #A9B2B9;
  .bloc-row {
      height: 100%;
  }
  .top-row {
    height: 50%;
  }
  .bottom-row {
    height: 50%;
    margin-left: 0.5em;
    font-size: 0.9em;
    .email {
      color: rgb(77, 77, 77);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .date {
      color: rgb(175, 175, 175);
      font-weight: 600;
    }
  }
  .col-8 {
    font-size: 1.2em;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-left: -0.5em;
  }
  .col-2 {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.item:hover {
  background-color: whitesmoke;
}

.item.disabled {
  pointer-events: none;
  opacity: 0.5;
}

@media screen and (min-width:1400px) {
  img {
    width: 1.5em;
    height: 1.5em;
    margin-left: 1em;
  }
}
@media screen and (max-width:1400px) {
  img {
    width: 1.5em;
    height: 1.5em;
    margin-left: 1em;
  }
}

img:hover {
  fill: @blue;
}

.b-icon {
  margin: 0;
}

</style>
