<template>
  <div class="element">
    <h2 v-if="!noTitle">
      {{ loading ? '' : count }} {{ text.title }}
    </h2>
    <div
      v-if="loading"
      class="item disabled d-flex justify-content-center  align-items-center"
    >
      <div
        class="spinner-border"
        role="status"
      />
    </div>
    <div
      v-else-if="count === 0"
      class="item"
      @click="$router.push({ name: routeTo.length > 1 ? routeTo[1] : routeTo[0] })"
    >
      <b-row align-v="center">
        <b-col cols="2">
          <img
            v-if="icon.length"
            :src="getSvgUrl()"
            alt="Icon main button"
          >
        </b-col>
        <b-col
          v-if="text.article && text.title"
          cols="8"
        >
          {{ capitalizeFirstLetter($t('words.create')) }} {{ text.article }} {{ text.title }}
        </b-col>
        <b-col
          v-else-if="!text.article && text.title"
          cols="8"
        >
          {{ text.title }}
        </b-col>
        <b-col
          v-if="icon !== 'add_plus'"
          cols="2"
        >
          <b-icon-plus
            class="h2"
            scale="1.5"
          />
        </b-col>
      </b-row>
    </div>
    <div
      v-else
      class="item"
      @click="$router.push({ name: routeTo[0] })"
    >
      <b-row align-v="center">
        <b-col cols="2">
          <img
            v-if="icon.length"
            :src="getSvgUrl()"
            alt="Icon main button"
          >
        </b-col>
        <b-col
          v-if="text.article && text.title"
          cols="8"
        >
          {{ capitalizeFirstLetter($t('words.see')) }} {{ text.article }} {{ text.title }}
        </b-col>
        <b-col
          v-else-if="!text.article && text.title"
          cols="8"
        >
          {{ text.title }}
        </b-col>
        <b-col cols="2">
          <b-icon-chevron-right
            class="h2"
            scale="1.5"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonItem',

  props: {
    count: {
      type: Number,
      default: 0
    },
    text: {
      type: Object,
      default: () => { return {}; }
    },
    icon: {
      type: String,
      default: ''
    },
    routeTo: {
      type: Array,
      default: () => { return []; }
    },
    loading: {
      type: Boolean,
      default: false
    },
    noTitle: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getSvgUrl() {
      const images = require.context('@/assets/icons/', false, /\.svg$/);
      return images(`./${this.icon}.svg`);
    }
  }
};
</script>

<style lang="less" scoped>

.item {
  cursor: pointer;
  width: 100%;
  height: 6em;
  color: @blue;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border: 4px solid #9bd0ff;
  box-shadow: 0 0 3px 1px #ccc;
  .row {
    height: 100%;
  }
  .col-8 {
    font-size: 1.7em;
    font-weight: bold;
    line-height: 1.7rem;
  }
}

.item:hover {
  background: @blue;
  border: 4px solid #9bd0ff;
  box-shadow: 0 0 3px 1px #ccc;
  color: white;
  img {
    filter: brightness(0%) invert(100%);
    -webkit-filter: brightness(0%) invert(100%);
    -moz-filter: brightness(0%) invert(100%);
  }
}

.item.disabled {
  pointer-events: none;
  opacity: 0.5;
}

@media screen and (min-width:1200px) {
  img {
    width: 3em;
    height: 3em;
    margin-left: 1em;
  }
}
@media screen and (max-width:1200px) {
  img {
    width: 2.5em;
    height: 2.5em;
    margin-left: 1em;
  }
}

img:hover {
  fill: @blue;
}

.b-icon {
  margin: 0;
}

</style>
